/*
	* Site Component
    * 
	* The Site component uses the twin model coordinate system to determine what context the twin data should be configured to,
    * in order to adopt the correct world space map position and orientation within the Mapbox context.
    * 
    * the coordinate systems currently in use are "COORD_LOCAL" ( for everything ) | "COORD_WGS84" ( for nothing ).
*/

import { ReactNode } from "react";
import { WallsAndFloor } from "./SiteBoundary";
import { Point, TwinEntity } from "@repo/backend-types";
import { degreesToRadians } from "../../utils/geometry";
import { Euler, Vector3Like } from "three";

interface Props {
	entity: TwinEntity;
	children?: ReactNode;
}

/**
	* Site Component
    * 
	* The Site component uses the twin model coordinate system to determine what context the twin data should be configured to,
    * in order to adopt the correct world space map position and orientation within the Mapbox context.
    * 
    * the coordinate systems currently in use are "COORD_LOCAL" ( Newport, Movico ).
*/

const Site = ({ entity, children }: Props) => {

    let orientation = entity.rotationY ?? 0; 

    if (entity.bID == 'station-gym') orientation -= 10.3 // hack, because it's changed for Newport and I'm not sure why
    const rotation: [number, number, number] = [0, degreesToRadians(orientation), 0 ]

    if (entity.boundaries) {

        // TODO is reducing this really necessary? 
        const boundingGeometry = entity.boundaries.polygons.map(polygon => polygon.coordinates).reduce((a, b) => a.concat(b));
        

        return  <group rotation={rotation}>
                        <WallsAndFloor boundingGeometry={boundingGeometry}/>
                        <group position={[0, 0.01, 0]}>
                        {children}
                        </group>        
                </group> 
        
    } else {
        return  <>
                    {children && <group rotation={rotation}>{children}</group>}
                </>
    }
    }

export { Site };
