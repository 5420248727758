// TODO - This is temporary and needs removing when we correctly have Twin (Site) preferences stored within the back-end

// Newport Live
import { twinPrefs as stationGymTwinPrefData } from "./newport-live/station-gym/twinPrefs"

// Movico
import { twinPrefs as movicoTwinPrefData } from "./movico/mu2-pilot/twinPrefs"

// Howler
import { twinPrefs as howlerDummyHowlerTwinPrefData } from "./howler/dummy-howler/twinPrefs";
import { twinPrefs as howler30678TwinPrefData } from "./howler/30678/twinPrefs";
import { twinPrefs as howler33054TwinPrefData } from "./howler/33054/twinPrefs";
import { twinPrefs as howler28695TwinPrefData } from "./howler/28695/twinPrefs";
import { twinPrefs as howler32845TwinPrefData } from "./howler/32845/twinPrefs";

// Total Fitness / Wilmslow
import { twinPrefs as tfWilmslowMainGymTwinPrefData } from "./total-fitness/wilmslow-main-gym/twinPrefs";
import { twinPrefs as tfWilmslowLadiesGymTwinPrefData } from "./total-fitness/wilmslow-ladies-gym/twinPrefs";
import { Settings } from "../../@types/Settings/Settings";

type SettingsLookup = Record<string, Record<string, Settings>>

export const preferencesLookup: SettingsLookup = {
    'Newport Live': {
        'station-gym': stationGymTwinPrefData
    },
    'Movico': {
        'Mu2-Pilot': movicoTwinPrefData,
    },
    'Total Fitness': {
        'wilmslow-main-gym': tfWilmslowMainGymTwinPrefData,
        'wilmslow-ladies-gym': tfWilmslowLadiesGymTwinPrefData,
        'Total Fitness Gym 1': tfWilmslowMainGymTwinPrefData
    },
    'Howler': {
        'dummy-howler': howlerDummyHowlerTwinPrefData,
        '30678': howler30678TwinPrefData,
        '33054': howler33054TwinPrefData,
        '32845': howler32845TwinPrefData,
        '28695': howler28695TwinPrefData
    },
   "Gym Co": {
        'GCO-Fin': tfWilmslowMainGymTwinPrefData,
        'CitySide': tfWilmslowMainGymTwinPrefData
    }
}
