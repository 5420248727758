import { TwinEntityType } from "../../@types/TwinEntityType";
import { stringToTwinEntityType } from "../../common/utils/stringToTwinEntityType";
import { TwinEntity } from "@repo/backend-types";
import { Structure } from "./twinEntities/structure/Structure";
import { Site } from "./twinEntities/site/Site";
import { Area } from "./twinEntities/area/Area";
import { HandleAsset } from "./twinEntities/asset/HandleAsset";
import { Wall } from "./Wall";
import { TempAsset } from "./twinEntities/asset/Asset";
import { EventSpace } from "./twinEntities/event/EventSpace";

type Props = {
    entity : TwinEntity
    mapDiv: HTMLDivElement
    loadingList: Map<string, boolean>
    depth: number // the depth we're at in the twin heirachy
    lineage: TwinEntity[] // the acenstry of this entity, oldest first
    mapOrigin: { latitude: number, longitude: number } // only one, set it in MapCanvas
}

export function TwinEntity3D({entity, mapDiv, loadingList, depth, lineage, mapOrigin} : Props): JSX.Element {

    const entityType = stringToTwinEntityType(entity.type.name)
    const ThisEntity = ({ children }: {children?: JSX.Element | JSX.Element[]} ) => {
    switch (entityType) {
        case TwinEntityType.EVENT: 
                return <EventSpace 
                            entity={entity} 
                            lineage={lineage} 
                            mapDiv={mapDiv}
                            loadingList={loadingList}
                        />
                        
        case TwinEntityType.AREA: 
                return <Area 
                            entity={entity} 
                            lineage={lineage} 
                            mapDiv={mapDiv}
                            depth={depth}
                        >
                            {children}
                        </Area>

        case TwinEntityType.ASSET:
            return  entity.modelFile ?  
                    <HandleAsset 
                        entity={entity} 
                        mapDiv={mapDiv} 
                        loadingList={loadingList} 
                        lineage={lineage}
                    /> :
                    <TempAsset entity={entity} mapDiv={mapDiv} lineage={lineage} />
    
        case TwinEntityType.CATEGORY: 
            // TODO: no existing entities in the graphs
            return <>{children}</>
       
        case TwinEntityType.FLOOR:
            // N.B. structures handle THEIR floors differently
            return <>{children}</>

        case TwinEntityType.STATION:
            // N.B. this is actually handled within EVENT
            return null

        case TwinEntityType.OPERATOR:
            // N.B. this is actually handled within EVENT
            return null

        case TwinEntityType.STRUCTURE:

            return <Structure
                    entity={entity}
                    mapOrigin={mapOrigin}
                    depth={depth}
                    childEntities={entity.children!}
                    modelFile={entity.modelFile!}
                    mapDiv={mapDiv}
                    loadingList={loadingList}
                    lineage={lineage}
                    />

        case TwinEntityType.SITE:
            return <Site entity={entity}>{children}</Site>

        case TwinEntityType.WALL:
            return <Wall entity={entity} depth={depth} />
        case TwinEntityType.NFAREA: 
            // TODO: no existing entities in the graphs
            return <>{children}</>
        case TwinEntityType.ZONE: 
            // TODO: no existing entities in the graphs
            return <>{children}</>
        case TwinEntityType.CHUTE: 
            // TODO: no existing entities in the graphs
            return <>{children}</>
        case TwinEntityType.TICKET: 
            // TODO: no existing entities in the graphs
            return <>{children}</>
        case TwinEntityType.UNKNOWN: 
            // TODO: no existing entities in the graphs
            return <>{children}</>
       }
    }
   

    if (entity.children && entityType !== TwinEntityType.STRUCTURE) { // structures inspect their own children
        return (
            <ThisEntity>
                { entity.children.map((childEntity, i) => {
                    return (
                        <TwinEntity3D 
                            key={i}
                            entity={childEntity} 
                            mapDiv={mapDiv} 
                            loadingList={loadingList} 
                            depth={depth + 1}
                            lineage={[...lineage, entity]}
                            mapOrigin={mapOrigin}
                        />)
                })}
    
            </ThisEntity>)
       
    } else {
        return <ThisEntity/>
    }
}